import React, { useState } from 'react';
import { Calendar } from 'react-feather';
import { useMutation } from '@apollo/react-hooks';
import { Link } from '@reach/router';
import { Modal, Dropdown, Button, FormErrors } from '@axeedge/go-teacher-components';
import EditReadSubscription from '../EditReadSubscription';
import { EXTEND_READ_SUBSCRIPTION, REDUCE_BOOMREAD_SUBSCRIPTION } from '../../services/graphql';
import moment from 'moment';
import { getInvStatusLabel } from '../../../../../Invoices/services/utils';
import { SUBSCRIPTION_STATUS } from '@axeedge/go-shared-utils';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from './Purchases.module.scss';
import cx from 'classnames';


const Purchases = ({ currentUser, schoolId, studentsUsingGoRead, invoiceRequests, subscriptions, setSubView, inWritingBee = false }) => {

    const [editSubscription, setEditSubscription] = useState();
    const [showExtend, setShowExtend] = useState(false);
    const [extendTo, setExtendTo] = useState({
        days: null,
        endDate: null
    });

    const [showReduce, setShowReduce] = useState(false);
    const [reduceTo, setReduceTo] = useState({
        days: null,
        endDate: null
    });

    const [formErrors, setFormErrors] = useState([]);
    const [formErrorsReduce, setFormErrorsReduce] = useState([]);

    const bookPackInvoices = invoiceRequests.filter(invoice => invoice.source === "BoomWriter Packs");
    const writePlusInvoices = invoiceRequests.filter(invoice => invoice.source === 'GoWrite');

    const notExpired = subscription => {
        return moment(new Date()).isBefore(new Date(subscription.expiresAt.substring(0, 10)))
    }


    const readSubscriptions = subscriptions.filter(s => s.app && s.app.name === "BoomReader");
    const orderedSubs = [...readSubscriptions].sort((a, b) => a.expiresAt.localeCompare(b.expiresAt));
    const currSub = orderedSubs.pop();

    const [extendBoomReadSubscription, { loading }] = useMutation(EXTEND_READ_SUBSCRIPTION, {
        update: (_, { data }) => {
            if (data.extendBoomReadSubscription.errors && data.extendBoomReadSubscription.errors.length !== 0) {
                setFormErrors(data.extendBoomReadSubscription.errors);
                return;
            }
            if (data.extendBoomReadSubscription.school.id) {
                setShowExtend(false);
                setExtendTo({
                    ...extendTo, days: null, endDate: null
                })
            }
        }
    });

    const [reduceBoomReadSubscription, { loading:reducing }] = useMutation(REDUCE_BOOMREAD_SUBSCRIPTION, {
        update: (_, { data }) => {
            if (data.reduceBoomReadSubscription.errors && data.reduceBoomReadSubscription.errors.length !== 0) {
                setFormErrorsReduce(data.reduceBoomReadSubscription.errors);
                return;
            }
            if (data.reduceBoomReadSubscription.school.id) {
                setShowReduce(false);
                setReduceTo({
                    ...reduceTo, days: null, endDate: null
                })
            }
        }
    });

    return (
        <div className={styles.invoices}>
            <div className={styles.invoicesCol}>
                <h2 className='u-m-base-2'>Book Pack Purchases</h2>
                <ul>
                    {
                        !!bookPackInvoices.length ? bookPackInvoices.map(invoice => (
                            <li key={`invoice-${invoice.id}`} className={styles.invoicesListItem}>
                                <Link to={`/invoices/${invoice.id}`}>Invoice {invoice.externalId ? `QB: ${invoice.externalId}` : `--`}</Link>
                                <span>{getInvStatusLabel(invoice.status)}</span>
                            </li>
                        )) : <p>No Book Pack purchases</p>
                    }
                </ul>

                {currentUser?.countryAdmin === 'IND' && !inWritingBee && (
                    <Button className='u-m-top-2' onClick={() => setSubView(3)}>Add to Bee</Button>
                )}
                <h2 className='u-m-base-2 u-m-top-3'>Write+ Purchases</h2>
                <ul>
                    {
                        !!writePlusInvoices.length ? writePlusInvoices.map(invoice => (
                            <li key={`invoice-${invoice.id}`} className={styles.invoicesListItem}>
                                <Link to={`/invoices/${invoice.id}`}>Invoice {invoice.externalId ? `QB: ${invoice.externalId}` : `--`}</Link>
                                <span>{getInvStatusLabel(invoice.status)}</span>
                            </li>
                        )) : <p>No Write+ purchases</p>
                    }
                </ul>
            </div>

            <div className={styles.invoicesCol}>
                <h2 className='u-m-base-2'>Read subscriptions</h2>
                <ul className='u-m-base-3'>
                    {
                        subscriptions.length > 0 ? subscriptions.filter(subscription => subscription.app && subscription.app.name === "BoomReader").sort((a, b) => b.expiresAt.localeCompare(a.expiresAt)).map(subscription => (
                            <li key={`s-${subscription.id}`} className={styles.invoicesListItem}>
                                <div>
                                    {subscription.invoiceRequest && <h3 className='u-m-base-2'>{subscription.invoiceRequest.studentsCounter} pupils</h3>}
                                    {subscription.invoiceRequest ? <p className={styles.invoicesReadActions}><Calendar className='u-m-right-1' /> {moment(new Date(subscription.invoiceRequest.orderedAt.substring(0, 10))).format('DD MMM YYYY')}- {moment(new Date(subscription.expiresAt.substring(0, 10))).format('DD MMM YYYY')}</p> :
                                        <p className={styles.invoicesReadActions}>Expires at: {moment(new Date(subscription.expiresAt.substring(0, 10))).format('DD MMM YYYY')}</p>
                                    }
                                    {
                                        notExpired(subscription) && subscription.invoiceRequest && (
                                            <p>Used: {studentsUsingGoRead}/{subscription.invoiceRequest.studentsCounter}</p>
                                        )
                                    }
                                </div>
                                <div>
                                    <div className={styles.invoicesReadActions}>
                                        {notExpired(subscription) && subscription.status === SUBSCRIPTION_STATUS.active && <span className='label label-primary'>Active</span>}
                                        {subscription.status === SUBSCRIPTION_STATUS.cancelled && <span className='label label-muted'>Cancelled</span>}
                                        {subscription.status === SUBSCRIPTION_STATUS.cancelled_renewal && <span className='label label-muted'>Cancelled renewal</span>}
                                        {subscription.status === SUBSCRIPTION_STATUS.trial_moved_to_paid && <span className='label label-muted'>Trial moved to Paid</span>}

                                        {(!notExpired(subscription) || subscription.status === SUBSCRIPTION_STATUS.expired) && <span className='label label-muted'>Expired</span>}

                                        <Dropdown classNames='u-m-left-2'>
                                            {subscription.invoiceRequest &&
                                                <li><Link to={`/invoices/${subscription.invoiceRequest.id}`}>View Invoice</Link></li>
                                            }

                                            {currSub?.id === subscription.id && (
                                                <li><button onClick={() => setShowExtend(true)} className='btn-reset'>Extend subscription</button></li>
                                            )}

                                            {currSub?.id === subscription.id && (
                                                <li><button onClick={() => setShowReduce(true)} className='btn-reset'>Reduce subscription</button></li>
                                            )}

                                            {
                                                notExpired(subscription) && (
                                                    <li><button className='btn-reset' onClick={() => setEditSubscription(subscription)}>Update status</button></li>
                                                )
                                            }
                                        </Dropdown>
                                    </div>

                                </div>
                            </li>
                        )) : <p>No subscriptions</p>
                    }
                </ul>
            </div>

            {showReduce && (
                <Modal closeModal={() => setShowReduce(false)}>
                    <h1>Reduce subscription:</h1>

                    <div className='u-m-top-3 u-m-base-3'>
                        <Button
                            {...(!reduceTo.days && { outline: true })}
                            onClick={() => setReduceTo({ ...reduceTo, days: 14, endDate: null })}
                        >14 days</Button>
                        <DatePicker
                            dateFormat='dd/MM/yyyy'
                            placeholderText='Choose date'
                            wrapperClassName={cx(styles.picker, { [styles.pickerActive]: reduceTo.endDate })}
                            className="basic-form__text-box picker"
                            selected={reduceTo.endDate}
                            maxDate={moment(currSub.expiresAt.substring(0, 10)).toDate()}
                            startDate={notExpired(currSub) ? moment(currSub.expiresAt.substring(0, 10)).toDate() : moment().toDate()}
                            onChange={(date) => setReduceTo({ ...reduceTo, endDate: date, days: null })}
                        />
                    </div>
                    <Button
                        className='u-m-right-2'
                        disabled={reducing || (!reduceTo.days && !reduceTo.endDate)}
                        onClick={() => reduceBoomReadSubscription({
                            variables: {
                                schoolId: schoolId,
                                ...(reduceTo.days && { reducedDays: reduceTo.days }),
                                ...(reduceTo.endDate && { endDate: moment(reduceTo.endDate).format('DD/MM/YYYY') })
                            }
                        })}
                    >Reduce subscription</Button>
                    <Button outline onClick={() => setShowReduce(false)}>Cancel</Button>
                    {formErrorsReduce && <FormErrors errors={formErrorsReduce} />}
                </Modal>
            )}
            {showExtend && (
                <Modal closeModal={() => setShowExtend(false)}>
                    <h1>Extend subscription:</h1>

                    <div className='u-m-top-3 u-m-base-3'>
                        <Button
                            {...(!extendTo.days && { outline: true })}
                            onClick={() => setExtendTo({ ...extendTo, days: 14, endDate: null })}
                        >14 days</Button>
                        <DatePicker
                            dateFormat='dd/MM/yyyy'
                            placeholderText='Choose date'
                            wrapperClassName={cx(styles.picker, { [styles.pickerActive]: extendTo.endDate })}
                            className="basic-form__text-box picker"
                            selected={extendTo.endDate}
                            startDate={moment().toDate()}
                            minDate={notExpired(currSub) ? moment(currSub.expiresAt.substring(0, 10)).toDate() : moment().toDate()}
                            onChange={(date) => setExtendTo({ ...extendTo, endDate: date, days: null })}
                        />
                    </div>
                    <Button
                        className='u-m-right-2'
                        disabled={loading || (!extendTo.days && !extendTo.endDate)}
                        onClick={() => extendBoomReadSubscription({
                            variables: {
                                schoolId: schoolId,
                                ...(extendTo.days && { extendedDays: extendTo.days }),
                                ...(extendTo.endDate && { endDate: moment(extendTo.endDate).format('DD/MM/YYYY') })
                            }
                        })}
                    >Extend subscription</Button>
                    <Button outline onClick={() => setShowExtend(false)}>Cancel</Button>
                    {formErrors && <FormErrors errors={formErrors} />}
                </Modal>
            )}

            {
                editSubscription && (
                    <Modal closeModal={() => setEditSubscription(null)}>
                        <EditReadSubscription closeModal={() => setEditSubscription(null)} subscription={editSubscription} />
                    </Modal>
                )
            }
        </div>
    )
}

export default Purchases;