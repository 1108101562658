
import { gql } from 'apollo-boost';

export const EXTEND_READ_SUBSCRIPTION = gql`
mutation extendBoomReadSubscription($schoolId: ID!, $extendedDays: Int, $endDate: String) {
    extendBoomReadSubscription(schoolId: $schoolId, extendedDays: $extendedDays, endDate: $endDate) {
        school {
            id
            schoolSubscriptions {
                id
                academicYear
                status
                expiresAt
                createdAt
                app {
                    id
                    name
                }
                isTrial
                invoiceRequest {
                    id
                    studentsCounter
                    orderedAt
                }
            }
        }
        errors
    }
}
`;

export const REDUCE_BOOMREAD_SUBSCRIPTION = gql`
mutation reduceBoomReadSubscription($schoolId: ID!, $reducedDays: Int, $endDate: String) {
    reduceBoomReadSubscription(schoolId: $schoolId, reducedDays: $reducedDays, endDate: $endDate) {
        errors
        school {
            id
            schoolSubscriptions {
                id
                academicYear
                status
                expiresAt
                createdAt
                app {
                    id
                    name
                }
                isTrial
                invoiceRequest {
                    id
                    studentsCounter
                    orderedAt
                }
            }
        }
    }
}
`;